var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-card', {
    attrs: {
      "title": "Daftar Penyimpanan Barang"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_vm.allowCreate() ? _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Add"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Add ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Batal ")])], 1)];
      },
      proxy: true
    }], null, false, 3622438321),
    model: {
      value: _vm.showModalAdd,
      callback: function ($$v) {
        _vm.showModalAdd = $$v;
      },
      expression: "showModalAdd"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('b-form-input', {
    attrs: {
      "id": "penyimpanan_id",
      "placeholder": "Nama Penyimpanan_id",
      "disabled": "",
      "hidden": ""
    },
    model: {
      value: _vm.id,
      callback: function ($$v) {
        _vm.id = $$v;
      },
      expression: "id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "namaBarang"
    }
  }, [_vm._v("Nama Barang")]), _c('b-form-input', {
    attrs: {
      "id": "namaBarang",
      "placeholder": "Nama Barang",
      "disabled": ""
    },
    model: {
      value: _vm.namaBarang,
      callback: function ($$v) {
        _vm.namaBarang = $$v;
      },
      expression: "namaBarang"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "tanggal"
    }
  }, [_vm._v("Tanggal")]), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      }
    },
    model: {
      value: _vm.tanggal,
      callback: function ($$v) {
        _vm.tanggal = $$v;
      },
      expression: "tanggal"
    }
  })], 1), _c('b-row', [_vm.optkonversi.length > 0 ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "jumlah"
    }
  }, [_vm._v("Jumlah")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "jumlah",
      "placeholder": "Jumlah"
    },
    model: {
      value: _vm.jumlah,
      callback: function ($$v) {
        _vm.jumlah = $$v;
      },
      expression: "jumlah"
    }
  })], 1)], 1) : _vm._e(), _vm.optkonversi.length > 0 ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "konversi"
    }
  }, [_vm._v("Konversi")]), _c('b-form-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "options": _vm.optkonversi,
      "id": "konversi",
      "placeholder": "konversi"
    },
    model: {
      value: _vm.id_satuan,
      callback: function ($$v) {
        _vm.id_satuan = $$v;
      },
      expression: "id_satuan"
    }
  })], 1)], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_vm.optkonversi.length > 0 ? _c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Hasil Konversi")]) : _vm._e(), _vm.optkonversi.length == 0 ? _c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Jumlah")]) : _vm._e(), _c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "nilai",
      "placeholder": "nilai"
    },
    model: {
      value: _vm.nilai,
      callback: function ($$v) {
        _vm.nilai = $$v;
      },
      expression: "nilai"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "satuan"
    }
  }, [_vm._v("Satuan")]), _c('b-form-input', {
    attrs: {
      "id": "satuan",
      "placeholder": "satuan",
      "disabled": ""
    },
    model: {
      value: _vm.satuan,
      callback: function ($$v) {
        _vm.satuan = $$v;
      },
      expression: "satuan"
    }
  })], 1)], 1)], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "keterangan"
    }
  }, [_vm._v("Keterangan")]), _c('b-form-textarea', {
    attrs: {
      "id": "keterangan",
      "placeholder": "Keterangan"
    },
    model: {
      value: _vm.keterangan,
      callback: function ($$v) {
        _vm.keterangan = $$v;
      },
      expression: "keterangan"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "id": "modal-ubah",
      "cancel-variant": "secondary",
      "ok-:title": "$t('Edit')",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Ubah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Ubah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Batal ")])], 1)];
      },
      proxy: true
    }], null, false, 459184876),
    model: {
      value: _vm.showModalEdit,
      callback: function ($$v) {
        _vm.showModalEdit = $$v;
      },
      expression: "showModalEdit"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "penyimpanan_id"
    }
  }, [_vm._v("Penyimpanan")]), _c('b-form-input', {
    attrs: {
      "id": "penyimpanan_id",
      "placeholder": "Nama Penyimpanan_id",
      "disabled": "",
      "hidden": ""
    },
    model: {
      value: _vm.id,
      callback: function ($$v) {
        _vm.id = $$v;
      },
      expression: "id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "tanggal"
    }
  }, [_vm._v("Tanggal")]), _c('b-form-datepicker', {
    staticClass: "form-control",
    attrs: {
      "id": "example-datepicker"
    },
    model: {
      value: _vm.tanggal,
      callback: function ($$v) {
        _vm.tanggal = $$v;
      },
      expression: "tanggal"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "jumlah"
    }
  }, [_vm._v("Jumlah")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "jumlah",
      "placeholder": "Jumlah"
    },
    model: {
      value: _vm.jumlah,
      callback: function ($$v) {
        _vm.jumlah = $$v;
      },
      expression: "jumlah"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "keterangan"
    }
  }, [_vm._v("Keterangan")]), _c('b-form-textarea', {
    attrs: {
      "id": "keterangan",
      "placeholder": "Keterangan"
    },
    model: {
      value: _vm.keterangan,
      callback: function ($$v) {
        _vm.keterangan = $$v;
      },
      expression: "keterangan"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
        _vm.getData();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "bordered": "",
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(stok)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.stok > 0 ? _vm.formatRupiah(item.stok) : item.stok))])];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_vm.allowCreate() && row.item.jumlah > 0 ? _c('b-button', {
          attrs: {
            "variant": "outline-primary",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              return _vm.add(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "PlusIcon"
          }
        }), _vm._v(" Disposal Hilang ")], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Daftar Disposal Hilang"
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Pilih Tanggal yang akan di print"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.unduhbop
          }
        }, [_vm._v(" Print / Cetak ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modalPrint,
      callback: function ($$v) {
        _vm.modalPrint = $$v;
      },
      expression: "modalPrint"
    }
  }, [_c('b-form', [_vm._v(" Dari : "), _c('b-form-group', [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "id": "example-datepicker"
    },
    model: {
      value: _vm.tgl,
      callback: function ($$v) {
        _vm.tgl = $$v;
      },
      expression: "tgl"
    }
  })], 1), _vm._v(" Ke : "), _c('b-form-group', [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "id": "example-datepicker"
    },
    model: {
      value: _vm.tglend,
      callback: function ($$v) {
        _vm.tglend = $$v;
      },
      expression: "tglend"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions2
    },
    model: {
      value: _vm.perPage2,
      callback: function ($$v) {
        _vm.perPage2 = $$v;
      },
      expression: "perPage2"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions2
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy2,
      callback: function ($$v) {
        _vm.sortBy2 = $$v;
      },
      expression: "sortBy2"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc2,
      callback: function ($$v) {
        _vm.sortDesc2 = $$v;
      },
      expression: "sortDesc2"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput2"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput2",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData2();
      }
    },
    model: {
      value: _vm.filter2,
      callback: function ($$v) {
        _vm.filter2 = $$v;
      },
      expression: "filter2"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter2
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
        _vm.getData2();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "bordered": "",
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage2,
      "current-page": _vm.currentPage2,
      "items": _vm.items2,
      "fields": _vm.fields2,
      "sort-by": _vm.sortBy2,
      "sort-desc": _vm.sortDesc2,
      "sort-direction": _vm.sortDirection2
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy2 = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy2 = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc2 = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc2 = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.jumlah > 0 ? _vm.formatRupiah(item.jumlah) : item.jumlah))])];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_vm.allowDelete() ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Hapus'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        }), _vm._v("Hapus ")], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows2,
      "per-page": _vm.perPage2,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage2,
      callback: function ($$v) {
        _vm.currentPage2 = $$v;
      },
      expression: "currentPage2"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }